
export default function initMenu(){
    const menuToggler = document.querySelectorAll('.navigation__toggle');
    const mobileMenu = document.querySelectorAll('.navigation__links--header, .mob-shadow');

    [].forEach.call(menuToggler, item => {
        item.addEventListener('click', menuToggle);
        item.addEventListener('touchstart', menuToggle);
    });

    function menuToggle(){
        console.log(mobileMenu);
        [].forEach.call(menuToggler[0].children, item => {
            item.classList.toggle('show');
        });
        [].forEach.call(mobileMenu, item => {
            item.classList.toggle('navigation__links--header-show');
        });
    }
}
