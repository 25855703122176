
import whenDOMReady from 'when-dom-ready'
import initMenu from "./menu.js"
import "core-js";

whenDOMReady()
    .then( () => {
        initMenu();
    });

try{


} catch(error){
    console.log(error);
}
